import Glide from '@glidejs/glide'

let sliderBlocks

/**
* initSlider takes any argument.
* @param sliderContainer - A block that contains slider with its track and navigation arrows
* @param sliderBlock - A class name of slides inside the slider track element 
*/
function initSlider(sliderContainer, sliderBlock) {
  let slider = sliderContainer.querySelector('.glide')
  if (slider) {
    let length = slider.dataset.slideCount
    let arrow_left = slider.querySelector('.glide__arrow--left')
    let arrow_right = slider.querySelector('.glide__arrow--right')
    
    sliderBlocks = Array.from(slider.querySelectorAll('.'+sliderBlock))

    var glide = new Glide(slider, {
      type: 'slider',
      keyboard: true,
      bound: true,
      peek: 60,
      perView: (length < 6 ) ? length : 6,
      gap: 10,
      afterInit: function() {
        arrow_left.toggleAttribute("disabled")
      },
      afterTransition: function() {
        arrow_left.toggleAttribute("disabled")
        arrow_right.toggleAttribute("disabled")
      },
      breakpoints: {
        480: {
          perView: 1
        },
        600: {
          perView: (length < 2 ) ? length : 2
        },
        1200: {
          perView: (length < 3 ) ? length : 3
        }
      }
    })
    // Track keybord arrrows press to scroll the slider
    slider.addEventListener('keyup', (e) => {
      if (e.keyCode == 39) glide.go('>')
      if (e.keyCode == 37) glide.go('<')
    });
    // Disable arrow if there is no more slides to scroll
    glide.on('move', function() {
      if (length == glide.settings.perView) {
        arrow_left.style.display = 'none'
        arrow_right.style.display = 'none'
      }
      else {
        arrow_left.style.display = 'block'
        arrow_right.style.display = 'block'
      }
      disableArrow(glide.index == 0, arrow_left)
      disableArrow(glide.index == length - glide.settings.perView, arrow_right)
    });
    var disableArrow = function(condition, arrow) {
      if (condition)
        arrow.setAttribute("disabled", "true")
      else if (arrow.hasAttribute("disabled"))
        arrow.removeAttribute("disabled")
    }
    glide.mount()
    // Hide dropdowns on slide change
    sliderBlocks.forEach((element) => {
      element.addEventListener("blur", hideAllDropdowns, true)
    })
    // Open slide's dropdown on mouse click 
    sliderBlocks.forEach((element) => {
      element.addEventListener("click", openOnFocusClick, true)
    })
    // Open slide's dropdown on tabulation
    sliderBlocks.forEach((element) => {
      element.addEventListener("focus", openOnFocusClick, true)
    })
  }
}

function openOnFocusClick(e) {
  let open = e.currentTarget.classList.contains("clicked")
  let elemInDropdown = e.target.classList.contains("nav-link")
  if (open && !elemInDropdown) {
    hideAllDropdowns()
  } else {
    hideAllDropdowns()
    showCurrentDropdown(e.currentTarget)
  }
}

function hideAllDropdowns() {
  sliderBlocks.forEach((element) => {
    element.classList.remove("clicked")
  })
}

function showCurrentDropdown(el) {
  el.classList.add("clicked")
}

export {
  initSlider
}